import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import "./App.css";
import RecruitPage from "./components/Recruit";

function App() {
  return (
    <div className="wrapper">
      <RecruitPage />
    </div>
  );
}

export default App;
