import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import load from "../assets/loading.gif";
// import "./App.css";

function RecruitPage() {
  const [loading, setloading] = useState(false);
  const [deleting, setDeleting] = useState({ id: "", val: false });
  const [updating, setUpdating] = useState(false);

  const [recruitsRes, setRecruitsRes] = useState(null);
  const [info, setInfo] = useState(null);
  const [error, setError] = useState([]);
  const [status, setstatus] = useState("all");
  const [updateStatus, setupdateStatus] = useState("status");
  const [checked, setchecked] = useState([]);

  const loadOrder = async (query) => {
    let api = `https://backend.thenobodyknowsit.club/getReruits?status=${
      query && query
    }`;
    let apiAll = "https://backend.thenobodyknowsit.club/getReruits";
    let res;
    console.log(query, "reuww");
    try {
      setloading(true);
      if (query === "pending" || query === "processed" || query === "current") {
        res = await axios.get(api);
      } else {
        res = await axios.get(apiAll);
      }

      console.log(res, "api");

      setRecruitsRes(res);
      setInfo(res);
      setloading(false);
    } catch (error) {
      setError(error);
      setloading(false);
    }
  };
  const removeRecruit = async (id) => {
    console.log(id);
    setDeleting({ id, val: true });
    const removeId = { id };
    try {
      await fetch(`https://backend.thenobodyknowsit.club/deleteReruits`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(removeId),
      });
      loadOrder();
    } catch (error) {
      console.log(error);
    }
    setDeleting({ id: "", val: false });
    setstatus("all");
  };
  const handleUpdate = async () => {
    setUpdating(true);
    let api = `https://backend.thenobodyknowsit.club/updateReruits`;
    // let api = "http://localhost:3300/updateReruits";
    try {
      await axios.put(api, { status: updateStatus || "pending", ids: checked });
      loadOrder();
    } catch (error) {
      setError(error);
    }
    setUpdating(false);
    setstatus("all");
    setchecked([]);
  };

  useLayoutEffect(() => {
    loadOrder();
  }, []);

  const handleStatusChange = (val) => {
    setstatus(val);
    loadOrder(val);
  };

  const handleCheck = (id) => {
    if (!checked.includes(id)) {
      setchecked([...checked, id]);
    } else {
      let newCheck = checked.filter((n) => n !== id);
      setchecked(newCheck);
    }
  };
  return (
    <div>
      <div className="welcome">
        <h1>Recruitment Update</h1>
      </div>
      <div className="select">
        <select name="sort" id="">
          <option value="all">All</option>
          <option value="today">Today</option>
          <option value="yesterday">Yesterday</option>
          <option value="this_Month">This Month</option>
        </select>
      </div>
      <div class="btnGroups">
        <button
          onClick={() => handleStatusChange("all")}
          className={status === "all" && "active"}
        >
          All
        </button>

        <button
          onClick={() => handleStatusChange("pending")}
          className={status === "pending" && "active"}
        >
          Pending
        </button>
        <button
          onClick={() => handleStatusChange("current")}
          className={status === "current" && "active"}
        >
          Current
        </button>
        <button
          onClick={() => handleStatusChange("processed")}
          className={status === "processed" && "active"}
        >
          Processed
        </button>
      </div>

      <div class="info">
        {loading ? (
          <div>
            <img src={load} alt="" width="100%" />
          </div>
        ) : (
          <>
            <p className="total">
              Total: {recruitsRes?.data?.recruits?.length}
            </p>
            {checked.length > 0 && (
              <div className="edit">
                <div className="select">
                  <select
                    name="sort"
                    id=""
                    onChange={(e) => setupdateStatus(e.target.value)}
                    value={updateStatus}
                  >
                    <option disabled="true" value="status">
                      Status
                    </option>
                    <option value="pending">Pending</option>
                    <option value="current">Current</option>
                    <option value="processed">Processed</option>
                  </select>
                </div>
                <button
                  onClick={() => handleUpdate()}
                  style={{
                    background: updating && "white",
                    border: updating && "2px solid black",
                    padding: updating && "0",
                  }}
                  disabled={updating}
                >
                  {updating ? <img src={load} alt="" width="80%" /> : "Update"}
                </button>
              </div>
            )}

            {recruitsRes?.data &&
              recruitsRes.data.recruits
                .slice(0)
                .reverse()
                .map((user, idx) => (
                  <div class="box card">
                    <input
                      type="checkbox"
                      name="select"
                      id=""
                      checked={checked.includes(user.id)}
                      onChange={() => handleCheck(user.id)}
                      style={{ marginRight: "10px" }}
                    />
                    <b>{idx + 1}</b>
                    <p
                      style={{
                        background: `${
                          user.status === "processed"
                            ? "green"
                            : user.status === "current"
                            ? "red"
                            : "yellow"
                        }`,
                        color: "black",
                        fontWeight: "bolder",
                        margin: 0,
                        padding: 0,
                        lineHeight: "25px",
                        textAlign: "center",
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {user.status}
                    </p>
                    <p class="time">
                      <span>{user.createdAt} </span>
                      <div>
                        <button
                          disabled={deleting.id === user.id}
                          class="cancel"
                          onClick={() => removeRecruit(user.id)}
                          style={{
                            background: deleting.id === user.id && "white",
                            border:
                              deleting.id === user.id && "2px solid black",
                            padding: deleting.id === user.id && "0",
                          }}
                        >
                          {deleting.id === user.id ? (
                            <img src={load} alt="" width="80%" />
                          ) : (
                            "delete"
                          )}
                        </button>
                      </div>
                    </p>
                    <p>
                      <span>Ans 1:</span> {user.name}
                    </p>
                    <p>
                      <span>Ans 2:</span> {user.email}
                    </p>
                    <p>
                      <span>Ans 3:</span> {user.phone}
                    </p>
                    <p>
                      <span>Ans 4:</span> {user.fixCampaign}
                    </p>
                    <p>
                      <span>Ans 5:</span> {user.isLaptop}
                    </p>
                    <p>
                      <span>Ans 6:</span> {user.isWordpress}
                    </p>
                    <p>
                      <span>Ans 7:</span> {user.mediBuyingTeams}
                    </p>
                    <p>
                      <span>Ans 8:</span> {user.mediaChannel}
                    </p>
                    <p>
                      <span>Ans 9:</span>
                      {user.videoUrl}{" "}
                      <a href={user.videoUrl} target="_blank">
                        view here:{" "}
                      </a>
                    </p>
                    <p>
                      <span>Ans 10:</span> {user.yearsOfExperience}
                    </p>
                  </div>
                ))}
          </>
        )}
      </div>
      <div class="btn">
        <button onClick={loadOrder}>Refresh Data</button>
      </div>
    </div>
  );
}

export default RecruitPage;
